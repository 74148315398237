import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';

const InstagramWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
`;

const InstagramGallery = styled('div')`
  display: grid;
  grid-template-columns: repeat(var(--cols), 1fr);
  gap: 1rem;
  width: 100%;
  max-width: 90rem;
  position: relative;

  padding-right: 2.25rem;
  padding-left: 2.25rem;
  ${theme.below.lg} {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
  }

  img,
  video {
    aspect-ratio: var(--aspect);
    width: 100%;
    height: auto;
  }
  ::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 100vw;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    z-index: -1;
  }
`;

const InstagramTitle = styled('div')`
  display: block;
  padding-top: 1rem;
  font-size: 1.5rem;
  color: #000000;
  font-weight: bold;
  margin-bottom: 10px;
  ${theme.below.lg} {
    font-size: 1.1rem;
  }
`;

const style = {
  InstagramWrapper,
  InstagramGallery,
  InstagramTitle
};

export default style;
