import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { ProductCardSmall } from './ProductCardSmall';
import { CarouselSmall, CarouselItemSmall } from './ui/CarouselSmall';
import Link from './ui/Link';

export function isBeforeScroll(index) {
  return index < 4;
}

export function ProductCarouselSmall({
  products,
  listName,
  loading,
  className,
  readMore,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <CarouselSmall data-testid="product-carousel">
      {products.map((product, index) => {
        return (
          <CarouselItemSmall key={index + ':' + product.articleNumber}>
            <ProductCardSmall
              as="section"
              product={product}
              style={{ opacity: loading ? 0.5 : 1 }}
              list={listName}
              loadImageEagerly={isBeforeScroll(index)}
              {...rest}
            />
          </CarouselItemSmall>
        );
      })}
      {readMore ? (
        <CarouselItemSmall align="center">
          <Link plain internalLink={readMore.to}>
            {readMore.text}
          </Link>
        </CarouselItemSmall>
      ) : null}
    </CarouselSmall>
  );
}

export default ProductCarouselSmall;
