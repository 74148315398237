import React from 'react';
import Styled from './Style';
import CategoryLink from './CategoryLink';
import useChildren from '../../hooks/useChildren';

const CategoryButtons = props => {
  const cleanedProps = useChildren(props);
  const categories = cleanedProps['categoryButton'];
  if (!categories) return null;

  const filteredCategories = categories.filter(
    ({ buttonLabel, internalLinkUrl }) => buttonLabel && internalLinkUrl
  );

  return (
    <Styled.CategoryButtonsWrapper>
      {filteredCategories.map(category => {
        const buttonLabel = category.buttonLabel
          ? category.buttonLabel
          : category?.category?.name;
        return (
          <Styled.CategoryButton key={category.key}>
            <CategoryLink
              internalLink={category.internalLinkUrl}
              category={category}
            >
              {buttonLabel ? (
                <Styled.ButtonLabel>{buttonLabel}</Styled.ButtonLabel>
              ) : null}
            </CategoryLink>
          </Styled.CategoryButton>
        );
      })}
    </Styled.CategoryButtonsWrapper>
  );
};

export default CategoryButtons;
