import React from 'react';
import useChildren from '../../hooks/useChildren';
import ContentBlockItemV2 from './ContentItemV2';
import Styled from './Style';

const ContentBlock = props => {
  const cleanedProps = useChildren(props);
  const contentItems = cleanedProps['contentBlockItemV2'];
  if (!contentItems) return null;

  return (
    <Styled.ContentBlockV2Container>
      <Styled.ContentBlockV2Wrapper
        frameColour={cleanedProps.frameColour}
        hasFrame={cleanedProps.boolFrame}
      >
        {contentItems.map(item => {
          return (
            <ContentBlockItemV2 contentBlockItemV2={item} key={item.key} />
          );
        })}
      </Styled.ContentBlockV2Wrapper>
    </Styled.ContentBlockV2Container>
  );
};

export default ContentBlock;
