import React from 'react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';

const linkStyles = css`
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

const CategoryLink = ({ internalLink, category, children }) => {
  if (internalLink) {
    return (
      <Link to={internalLink} className={linkStyles}>
        {children}
      </Link>
    );
  }
  return category?.category?.primaryRoute?.path ? (
    <Link to={category.category.primaryRoute.path} className={linkStyles}>
      {children}
    </Link>
  ) : null;
};

export default CategoryLink;
