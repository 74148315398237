import React from 'react';
import Image from '@jetshop/ui/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import Styled from './Style';
import NonLinkCTA from '../../components/ui/NonLinkCTA';
import BannerLink from '../../components/ui/BannerLink';
import FadeInComponent from '../../components/Animations/Animations';

const ContentBlockItemV2 = ({ contentBlockItemV2 }) => {
  const imageSizes = [1, 1, 1 / 2, 1 / 2, 1 / 2, 676];
  return (
    <Styled.ContentBlockItemV2Wrapper>
      <FadeInComponent>
        {(contentBlockItemV2.externalLinkUrl ||
          contentBlockItemV2.internalLinkUrl) && (
          <Styled.ContentContainer>
            <Below breakpoint="md">
              {matches =>
                matches ? (
                  <Styled.ImageWrapper className="below-md">
                    <Image
                      src={contentBlockItemV2.backgroundImage}
                      aspect="335:357"
                      fillAvailableSpace={true}
                      sizes={imageSizes}
                      crop
                    />
                  </Styled.ImageWrapper>
                ) : (
                  <Styled.ImageWrapper className="above-md">
                    <Image
                      src={contentBlockItemV2.backgroundImage}
                      aspect="169:89"
                      fillAvailableSpace={true}
                      sizes={imageSizes}
                      crop
                    />
                  </Styled.ImageWrapper>
                )
              }
            </Below>

            {contentBlockItemV2.boolFrostedGlass && <Styled.FrostedGlassV2 />}
            <BannerLink
              externalLink={contentBlockItemV2.externalLinkUrl}
              internalLink={contentBlockItemV2.internalLinkUrl}
            >
              <Styled.InnerWrapper hasIcon={!!contentBlockItemV2.icon}>
                {contentBlockItemV2.icon && (
                  <Styled.IconImage
                    src={contentBlockItemV2.icon}
                    alt=""
                    role="presentation"
                  />
                )}
                <Styled.Title level="3" asLevel="4">
                  <Styled.TextColour textColour={contentBlockItemV2.textColour}>
                    {contentBlockItemV2.title}
                  </Styled.TextColour>
                </Styled.Title>
                {contentBlockItemV2.text && (
                  <Styled.Text>
                    <Styled.TextColour
                      textColour={contentBlockItemV2.textColour}
                    >
                      {contentBlockItemV2.text}
                    </Styled.TextColour>
                  </Styled.Text>
                )}
                {contentBlockItemV2.linkLabel &&
                  (contentBlockItemV2.externalLinkUrl ||
                    contentBlockItemV2.internalLinkUrl) && (
                    <NonLinkCTA>{contentBlockItemV2.linkLabel}</NonLinkCTA>
                  )}
              </Styled.InnerWrapper>
            </BannerLink>
          </Styled.ContentContainer>
        )}
        {!contentBlockItemV2.externalLinkUrl &&
          !contentBlockItemV2.internalLinkUrl && (
            <Styled.ContentContainer>
              <Below breakpoint="md">
                {matches =>
                  matches ? (
                    <Styled.ImageWrapper className="below-md">
                      <Image
                        src={contentBlockItemV2.backgroundImage}
                        aspect="335:357"
                        fillAvailableSpace={true}
                        sizes={imageSizes}
                        crop
                      />
                    </Styled.ImageWrapper>
                  ) : (
                    <Styled.ImageWrapper className="above-md">
                      <Image
                        src={contentBlockItemV2.backgroundImage}
                        aspect="169:89"
                        fillAvailableSpace={true}
                        sizes={imageSizes}
                        crop
                      />
                    </Styled.ImageWrapper>
                  )
                }
              </Below>

              {contentBlockItemV2.boolFrostedGlass && <Styled.FrostedGlassV2 />}
              <Styled.InnerWrapper hasIcon={!!contentBlockItemV2.icon}>
                {contentBlockItemV2.icon && (
                  <Styled.IconImage
                    src={contentBlockItemV2.icon}
                    alt=""
                    role="presentation"
                  />
                )}
                <Styled.Title level="3" asLevel="4">
                  <Styled.TextColour textColour={contentBlockItemV2.textColour}>
                    {contentBlockItemV2.title}
                  </Styled.TextColour>
                </Styled.Title>
                {contentBlockItemV2.text && (
                  <Styled.Text>
                    <Styled.TextColour
                      textColour={contentBlockItemV2.textColour}
                    >
                      {contentBlockItemV2.text}
                    </Styled.TextColour>
                  </Styled.Text>
                )}
              </Styled.InnerWrapper>
            </Styled.ContentContainer>
          )}
      </FadeInComponent>
    </Styled.ContentBlockItemV2Wrapper>
  );
};

export default ContentBlockItemV2;
