import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';

const CategoryBlockWrapper = styled(MaxWidth)`
  margin-bottom: 1rem;
  ${theme.above.md} {
    flex-direction: row;
  }
  ${theme.below.md} {
    flex-direction: column;
  }
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ::-webkit-scrollbar {
    display: none;
  }

  ${theme.above.lg} {
    padding-right: ${toRem(36)};
    padding-left: ${toRem(36)};
  }
  ${theme.below.lg} {
    padding: 0;
  }

  ${theme.above.xl} {
    overflow-x: initial;
  }
`;

const CategoryTextContent = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${toRem(24)};
  padding-left: ${toRem(24)};
  padding-right: ${toRem(12)};
  ${theme.above.md} {
    flex-direction: row;
  }
  ${theme.below.md} {
    flex-direction: column;
  }
`;

const CategoryTitle = styled.h2`
  font-size: ${toRem(24)};
  line-height: ${toRem(25)};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colorScheme.white};

  ${theme.below.md} {
    padding-bottom: ${toRem(14)};
  }

  ${theme.above.md} {
    font-size: ${toRem(28)};
    line-height: ${toRem(29)};
    padding-bottom: ${toRem(14)};
  }

  ::before {
    content: '';
    width: ${toRem(45)};
    height: ${toRem(3)};
    background-color: ${theme.colorScheme.primary};
    position: absolute;
    bottom: ${toRem(24)};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colorScheme.black};
  ${theme.below.md} {
    div {
      position: static !important;
    }
  }
`;

const CategoryItem = styled.div`
  min-height: ${toRem(356)};
  min-width: ${toRem(240)};
  display: flex;
  flex: 0 0 auto;
  position: relative;

  ${theme.above.md} {
    &:last-child {
      margin-right: 0;
    }
  }

  ${theme.below.md} {
    margin-left: 1rem;
    margin-right: 1rem;
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  ${theme.above.sm} {
    min-width: initial;
    flex: 1;
  }

  ${theme.above.lg} {
    min-height: ${toRem(356)};
    margin-right: 1rem;
  }

  :hover {
    ${CategoryTitle} {
      padding-bottom: ${toRem(20)};
      animation: hoverUp 0.25s;
      transition-timing-function: ease-in;
    }
    ${ImageWrapper} {
      > div {
        opacity: 0.7;
        animation: imageOpacity 0.25s;
        transition-timing-function: ease-in;
      }
    }
  }
  @keyframes hoverUp {
    from {
      padding-bottom: ${toRem(14)};
    }
    to {
      padding-bottom: ${toRem(20)};
    }
  }
  @keyframes imageOpacity {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.7;
    }
  }
`;
const style = {
  CategoryBlockWrapper,
  CategoryItem,
  CategoryTextContent,
  CategoryTitle,
  ImageWrapper
};

export default style;
