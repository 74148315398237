import React from 'react';
import useChildren from '../../hooks/useChildren';
import ContentItem from './ContentItem';
import Styled from './Style';

const ContentBlock = props => {
  const cleanedProps = useChildren(props);
  const contentItems = cleanedProps['contentBlockItem'];

  if (!contentItems) return null;

  return (
    <Styled.ContentBlockContainer>
      <Styled.ContentBlockWrapper>
        {contentItems.map(item => {
          return <ContentItem contentItem={item} key={item.key} />;
        })}
      </Styled.ContentBlockWrapper>
    </Styled.ContentBlockContainer>
  );
};

export default ContentBlock;
