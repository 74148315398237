import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';

const BannerLinkStyles = css`
  display: flex;
  background: none;
  color: inherit;
  border: none;
  text-decoration: none;
  :hover {
    background-color: none;
    color: inherit;
    border: none;
    text-decoration: none;
  }
`;

const BannerLink = ({ children, internalLink, externalLink }) => {
  if (externalLink) {
    return (
      <a
        target={'_blank'}
        rel={'noreferrer'}
        href={externalLink}
        className={cx(BannerLinkStyles)}
      >
        {children}
      </a>
    );
  }

  if (internalLink) {
    return (
      <Link to={internalLink} className={cx(BannerLinkStyles)}>
        {children}
      </Link>
    );
  }
};

export default BannerLink;
