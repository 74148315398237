import React, { useState, useEffect } from 'react';
import Image from '@jetshop/ui/Image';
import Button from '../../components/ui/Button';
import { css, cx } from 'linaria';
import { theme } from '../../components/Theme';
import useChildren from '../../hooks/useChildren';
import MaxWidth from '../../components/Layout/MaxWidth';
import Styled from './Style';

// Define a base CSS class for the Button
const buttonBaseStyle = css`
  svg path {
    fill: currentColor;
  }
  :hover:not(:disabled) svg path {
    fill: currentColor;
  }
`;

export default function ColouredContentBlock(props) {
  const {
    align = 'left',
    image,
    imageAlt = '',
    imageAlign = 'left',
    linkUrl,
    linkText,
    children,
    fullWidth,
    withPadding,
    backgroundColour = '#666666',
    textColour = '#ffffff'
  } = useChildren(props);

  const [widthCheck, setWidthCheck] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidthCheck(
        window.matchMedia('(max-width: 63.99rem)').matches || fullWidth
      );
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [fullWidth]);

  const content = props.content;
  const widthStr = widthCheck
    ? {
        backgroundColor: backgroundColour,
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: '100%',
        marginBottom: '1rem'
      }
    : { marginBottom: '1rem', paddingLeft: '2.25rem', paddingRight: '2.25rem' };

  return (
    <MaxWidth style={widthStr}>
      <Styled.ColouredContentWrapper>
        <section
          style={{ backgroundColor: backgroundColour }}
          className={cx(
            infoBlockClassName,
            align && `align-${align}`,
            image && 'withImage',
            image && imageAlign && `imageAlign-${imageAlign}`,
            linkUrl && linkText && 'withLink',
            fullWidth && 'fullWidth',
            withPadding && 'withPadding'
          )}
        >
          {image ? (
            <figure className="imageWrapper">
              <Image
                cover
                className="image"
                aspect="16:9"
                {...{ src: image }}
                alt={imageAlt}
              />
            </figure>
          ) : null}
          <div className="contentWrapper">
            {content || children ? (
              <div className="content">
                {content?.type === 'html' && (
                  <div
                    className="html"
                    dangerouslySetInnerHTML={{
                      __html: content?.value?.value || content?.value || null
                    }}
                    style={{ color: textColour }}
                  />
                )}
                {children}
              </div>
            ) : null}
            {linkUrl && linkText ? (
              <footer className="footer">
                <Button
                  className={cx('button', buttonBaseStyle)}
                  href={linkUrl}
                  style={{
                    color: backgroundColour,
                    backgroundColor: textColour,
                    borderColor: textColour
                  }}
                >
                  {linkText}
                </Button>
              </footer>
            ) : null}
          </div>
        </section>
      </Styled.ColouredContentWrapper>
    </MaxWidth>
  );
}

const infoBlockClassName = css`
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  position: relative;
  z-index: 1;
  width: 100%;
  &.withPadding {
    padding: 1rem;
  }
  &.fullWidth::before {
    content: '';
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100vw;
    transform: translateX(-50%);
  }
  &.fullWidth {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  &.align-center {
    text-align: center;
    align-items: center;
    .contentWrapper {
      align-items: center;
    }
  }
  &.align-right {
    text-align: right;
    align-items: end;
    .contentWrapper {
      align-items: end;
    }
  }
  ${theme.above.md} {
    &.imageAlign-left {
      flex-direction: row;
      align-items: center;
      .contentWrapper {
        padding-left: 2.25rem;
      }
    }
    &.imageAlign-right {
      flex-direction: row-reverse;
      align-items: center;
      .contentWrapper {
        padding-right: 2.25rem;
      }
    }
    &.imageAlign-top {
      .imageWrapper {
        flex: 1 1 100%;
      }
    }
  }
  ${theme.above.xl} {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
  }

  .imageWrapper {
    flex: 1 1 50%;
    margin: 0;
    width: 100%;
  }
  .contentWrapper {
    flex: 1 1 50%;
    padding: 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    ${theme.above.xl} {
      max-width: 90rem;
      padding: 1rem 2.25rem 1rem;
    }
  }
  .content {
    .html {
      line-height: 1.5rem;
      h1 {
        line-height: normal;
      }

      a {
        color: inherit;
      }
      p {
        margin: 0.5rem 0;
      }
      strong {
        font-weight: ${theme.fontWeights.medium};
      }
      ul {
        list-style: initial;
        padding: 1rem 0 1rem 1.5rem;
      }
    }
  }
  .footer {
    margin-top: 1rem;
  }
`;
