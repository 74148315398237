import React from 'react';

export default function Text({
  children, // Node | Node[]
  clearHTML = true
}) {
  return [
    ...(Array.isArray(children) ? children : [children])
  ].map((child, index) => (
    <p key={index}>
      {clearHTML && typeof child === 'string'
        ? child.replace(/<\/?[^>]*>/g, '')
        : child}
    </p>
  ));
}
