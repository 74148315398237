import React from 'react';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import Image from '@jetshop/ui/Image';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import { toRem } from '../../helpers';
import { theme } from '../../components/Theme';
import useChildren from '../../hooks/useChildren';
import MaxWidth from '../../components/Layout/MaxWidth';

export default function ImageBlock(props) {
  const {
    image, // string
    alt = '',
    title, // string
    content, // string
    children // Node
  } = useChildren(props);

  return image ? (
    <Wrapper>
      <div className={cx(imageBlockClassName)}>
        <figure className="imageWrapper">
          <Image
            fillAvailableSpace
            aspect="16:9"
            crop={true}
            className="image"
            {...{ src: image, alt }}
          />
        </figure>
        {title || content ? (
          <div className="contentWrapper">
            {title ? (
              <Heading
                className="title"
                level={1}
                asLevel={3}
                noMargin={!(content || children)}
              >
                {title}
              </Heading>
            ) : null}
            {content ? <Text>{content}</Text> : null}
            {children}
          </div>
        ) : null}
      </div>
    </Wrapper>
  ) : null;
}

const Wrapper = styled(MaxWidth)`
  ${theme.below.lg} {
    padding: 0;
  }
`;
const imageBlockClassName = css`
  width: 100%;
  margin: auto;
  max-width: ${toRem(theme.widths.maxWidth)};
  position: relative;
  z-index: 1;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  .imageWrapper {
    background: ${theme.colorScheme.black};
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
  }

  .contentWrapper {
    text-align: left;
    width: 70%;
    padding: 2rem;
    color: ${theme.colorScheme.white};
    &::before {
      content: '';
      z-index: -1;
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-90%, -50%) rotate(-17.5deg);
      transform-origin: 100% -30%;
      backdrop-filter: blur(5px);
      width: 200%;
      height: 200%;
    }
    ${theme.above.sm} {
      width: 60%;
    }
    ${theme.above.md} {
      width: 50%;
      &::before {
        transform-origin: 100% -10%;
      }
    }
    ${theme.above.lg} {
      width: 40%;
      &::before {
        transform-origin: 100% 10%;
      }
    }
  }
`;
