import React from 'react';
import Image from '@jetshop/ui/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import Styled from './Style';
import CTALink from '../../components/ui/Link';

const ContentItem = ({ contentItem }) => (
  <Styled.ContentItemWrapper>
    <Below breakpoint="md">
      {matches =>
        matches ? (
          <Styled.ImageWrapper className="below-md">
            <Image
              src={contentItem.backgroundImage}
              aspect="335:357"
              fillAvailableSpace={true}
            />
          </Styled.ImageWrapper>
        ) : (
          <Styled.ImageWrapper className="above-md">
            <Image
              src={contentItem.backgroundImage}
              aspect="169:89"
              fillAvailableSpace={true}
            />
          </Styled.ImageWrapper>
        )
      }
    </Below>
    <Styled.FrostedGlass />
    <Styled.InnerWrapper hasIcon={!!contentItem.icon}>
      {contentItem.icon && (
        <Styled.IconImage src={contentItem.icon} alt="" role="presentation" />
      )}
      <Styled.TextCTAWrapper>
        <Styled.Title level="3" asLevel="4">
          {contentItem.title}
        </Styled.Title>
        {contentItem.text && <Styled.Text>{contentItem.text}</Styled.Text>}
        {contentItem.linkLabel &&
          (contentItem.externalLinkUrl || contentItem.internalLinkUrl) && (
            <CTALink
              externalLink={contentItem.externalLinkUrl}
              internalLink={contentItem.internalLinkUrl}
            >
              {contentItem.linkLabel}
            </CTALink>
          )}
      </Styled.TextCTAWrapper>
    </Styled.InnerWrapper>
  </Styled.ContentItemWrapper>
);

export default ContentItem;
