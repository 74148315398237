import React from 'react';
import { Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import Styled from './Style';
import useChildren from '../../hooks/useChildren';

const getAspectRatio = icons => {
  switch (icons.length) {
    case 4:
      return '1:1';
    default:
      return '1:1';
  }
};

const IconsBlock = props => {
  const cleanedProps = useChildren(props);
  const icons = cleanedProps['iconsItem'];

  if (!icons) return null;

  const aspectRatio = getAspectRatio(icons);
  return (
    <Styled.IconsBlockWrapper>
      {icons
        .filter(({ title, backgroundImage }) => title && backgroundImage)
        .map(icon => {
          const title = icon.title;
          return (
            <Styled.IconsItem key={icon.key}>
              <Below breakpoint="md">
                {matches =>
                  matches ? (
                    <Styled.IconImageWrapper className="below-md">
                      <Image
                        aspect={aspectRatio}
                        src={icon.backgroundImage}
                        fillAvailableSpace={false}
                      />
                    </Styled.IconImageWrapper>
                  ) : (
                    <Styled.IconImageWrapper className="above-md">
                      <Image
                        aspect={aspectRatio}
                        src={icon.backgroundImage}
                        fillAvailableSpace={false}
                      />
                    </Styled.IconImageWrapper>
                  )
                }
              </Below>
              {title ? (
                <Styled.IconsTextContent
                  dangerouslySetInnerHTML={{
                    __html: title || null
                  }}
                ></Styled.IconsTextContent>
              ) : null}
            </Styled.IconsItem>
          );
        })}
    </Styled.IconsBlockWrapper>
  );
};

export default IconsBlock;
