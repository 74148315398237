import React, { useEffect, useRef, useState } from 'react';
import './styles.css';

const FadeInComponent = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={`fade-in ${isVisible ? 'fade-in-visible' : ''} zoom-full ${
        isVisible ? 'zoom-full-visible' : ''
      }`}
    >
      {children}
    </div>
  );
};

export default FadeInComponent;
