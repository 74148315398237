import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';

const CategoryButtonsWrapper = styled(MaxWidth)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-top: 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  ${theme.above.xl} {
    overflow-x: initial;
  }
  ${theme.below.lg} {
    align-items: left;
    justify-content: left;
  }
`;

const ButtonLabel = styled.h2`
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.medium};
  line-height: 20px;
  color: ${theme.colorScheme.black};
  list-style: none;

  ${theme.above.md} {
    font-size: ${toRem(16)};
  }

  &:child {
    text-decoration: none;
  }
`;
// color: #333333;

const CategoryButton = styled.div`
  background-color: rgba(51, 51, 51, 0.1);
  border-width: 0;
  display: inline-block;
  position: relative;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  text-align: center;
  padding: 10px 12px;
  transition: all 200ms;

  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  ${theme.below.lg} {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const style = {
  CategoryButtonsWrapper,
  CategoryButton,
  ButtonLabel
};

export default style;
