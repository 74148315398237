import { styled } from 'linaria/react';
import MaxWidth from '../../components/Layout/MaxWidth';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';

const IconsBlockWrapper = styled(MaxWidth)`
  @media (max-width: 40rem) {
    flex-wrap: wrap;
    max-width: none;
  }
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const IconsTextContent = styled.div`
  @media (max-width: 40rem) {
    bottom: 0;
    display: block;
    padding-left: ${toRem(10)};
    padding-right: ${toRem(10)};
    margin-top: 10px;
  }
`;

const IconsTitle = styled.h2`
  font-size: ${toRem(10)};
  line-height: ${toRem(10)};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colorScheme.black};
  margin-bottom: ${toRem(0)};

  ${theme.above.md} {
    font-size: ${toRem(12)};
    line-height: ${toRem(12)};
  }
`;

const IconImageWrapper = styled.div`
  width: 50px;
  height: 65px;
  background-color: ${theme.colorScheme.white};
  margin: auto;
  @media (max-width: 40rem) {
    height: 30px;
  }
`;

const IconsItem = styled.div`
  @media (max-width: 40rem) {
    width: 165px;
    height: 137px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    padding-bottom: 25px;
    text-align: center;
    flex: none;
    flex-direction: column;
    line-height: 1;
  }
  flex: 1 1;
  padding: 20px 15px 25px;
  text-align: center;
  height: 100%;
  position: relative;

  ${theme.above.sm} {
    min-width: initial;
    flex: 1;
  }

  ${theme.above.lg} {
    min-height: ${toRem(10)};
    margin-right: ${toRem(12)};
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 40rem) {
    &:not(:last-of-type):after {
      content: '';
      position: absolute;
      right: 0;
      border-right: 1px solid #000;
      height: 80%;
      top: 50%;
      opacity: 0.2;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  @media (max-width: 40rem) {
    &:nth-child(odd):after {
      content: '';
      position: absolute;
      right: 0;
      border-right: 1px solid #000;
      height: 80%;
      top: 50%;
      opacity: 0.2;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:first-child:before,
    &:nth-child(2):before {
      content: '';
      position: absolute;
      left: 50%;
      border-bottom: 1px solid #000;
      width: 80%;
      bottom: 0;
      opacity: 0.2;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
`;
const style = {
  IconsBlockWrapper,
  IconsItem,
  IconsTextContent,
  IconsTitle,
  IconImageWrapper
};

export default style;
