import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';

const ColouredContentWrapper = styled('div')`
  padding: 0;
  max-width: 90rem;
  margin: auto;
  ${theme.below.lg} {
    width: 100%;
  }
`;

const style = {
  ColouredContentWrapper
};

export default style;
