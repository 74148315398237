import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../components/Theme';
import { ProductGrid } from '../../components/ProductGrid';
import ProductCarousel from '../../components/ProductCarousel';
import Heading from '../../components/ui/Heading';
import MaxWidth from '../../components/Layout/MaxWidth';
import FullWidth from '../../components/Layout/FullWidth';
import { Above } from '@jetshop/ui/Breakpoints';
import { useAddwish } from '@jetshop/flight-addwish';
import helloRetailQuery from '../../components/HelloRetail/HelloRetailQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import helloRetailIds from '../../components/HelloRetail/RecommendationIds';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import routeQuery from '../../gql/queries/RouteQuery.gql';

const Container = styled('div')`
  padding-top: 1rem;
  position: relative;
  margin-bottom: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    background: ${theme.colorScheme.superlightgrey};
  }
  ${theme.below.sm} {
    padding-bottom: 4px;
  }
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
export const productGrid = css`
  flex-wrap: nowrap !important;
  ${theme.below.md} {
    overflow-x: auto;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 340px;
    }
  }
  ${theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    ${theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
    }
  }
`;

const linkStyles = css`
  text-decoration: none;
  color: #000;
`;

const getHelloRetailRecId = (recomName, selectedChannel) => {
  return helloRetailIds[selectedChannel?.name][recomName];
};

const HelloRetailProductList = ({
  loading = false,
  recomName,
  title,
  category,
  ...rest
}) => {
  const { selectedChannel } = React.useContext(ChannelContext);
  const { pathname } = useLocation();
  var routeArray = [];
  var routePath = '/';
  if (category.value) {
    routePath = category.value.primaryRoute.path;
  }
  const routeQueryResult = useQuery(routeQuery, {
    variables: {
      path: routePath
    }
  });
  if (routeQueryResult.data && category.value) {
    for (var i = routeQueryResult.data.route.parents.length - 1; i >= 0; i--) {
      routeArray.push(
        routeQueryResult.data.route.parents[i].object.breadcrumbText
      );
    }
    routeArray.push(category.value.name);
  }

  const relatedId = getHelloRetailRecId(recomName.value, selectedChannel);

  const url = `${selectedChannel?.url}${pathname}`;
  const hierarchies = [routeArray];
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [relatedId]: {
        url,
        hierarchies
      }
    },
    pageLoaded: !loading && (routeArray.length > 0 || !category.value)
  });
  const relatedArticleNumbers = (
    addWishResponseBoxItems[relatedId]?.items || []
  ).map(item => item.productNumber);

  const relatedProductResult = useQuery(helloRetailQuery, {
    variables: {
      articleNumbers: relatedArticleNumbers
    },
    skip: relatedArticleNumbers.length === 0
  });

  const relatedProducts =
    relatedProductResult?.data?.products.map(product => {
      return {
        ...product,
        addwishData: addWishResponseBoxItems[relatedId]?.items.find(
          item => item.productNumber === product.articleNumber
        )
      };
    }) || [];

  var headerLink = '';
  if (!category.value) {
    headerLink = '0';
  } else {
    headerLink = category.value.primaryRoute.path;
  }

  const primaryRoute = headerLink;

  return !relatedProducts.length ? null : (
    <Container>
      {title?.value && primaryRoute !== '0' ? (
        <MaxWidth>
          <Heading level="2" asLevel={4} underline>
            <Link to={primaryRoute} className={linkStyles}>
              {title.value}
            </Link>
          </Heading>
        </MaxWidth>
      ) : null}
      {title?.value && primaryRoute === '0' ? (
        <MaxWidth>
          <Heading level="2" asLevel={4} underline>
            {title.value}
          </Heading>
        </MaxWidth>
      ) : null}
      <Above breakpoint="xl">
        {matches =>
          matches ? (
            <MaxWidth className="above-xl">
              <ProductGrid
                products={relatedProducts}
                className={productGrid}
                widths={{
                  xl: title?.value ? 1 / 6 : 1 / 5 //title added temporarily to width and readMore buttons.
                }}
              />
            </MaxWidth>
          ) : (
            <FullWidth className="below-xl">
              <ProductCarousel
                products={relatedProducts}
                className={productGrid}
              />
            </FullWidth>
          )
        }
      </Above>
    </Container>
  );
};

export default HelloRetailProductList;
