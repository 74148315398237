import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { ProductCard } from './ProductCard';
import { Carousel, CarouselItem } from './ui/Carousel';
import Link from './ui/Link';

export function isBeforeScroll(index) {
  return index < 4;
}

export function ProductCarousel({
  products,
  listName,
  loading,
  className,
  readMore,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <Carousel data-testid="product-carousel">
      {products.map((product, index) => {
        return (
          <CarouselItem key={index + ':' + product.articleNumber}>
            <ProductCard
              imageSizes={[1, 2 / 3, 1 / 2, 1 / 3, 1 / 3]}
              as="section"
              product={product}
              style={{ opacity: loading ? 0.5 : 1 }}
              list={listName}
              loadImageEagerly={isBeforeScroll(index)}
              {...rest}
            />
          </CarouselItem>
        );
      })}
      {readMore && readMore.to !== '0' ? (
        <CarouselItem align="center">
          <Link plain internalLink={readMore.to}>
            {readMore.text}
          </Link>
        </CarouselItem>
      ) : null}
    </Carousel>
  );
}

export default ProductCarousel;
